// Shadows for the logo, on and none
$onair-shadow-on: 0 0 10px $primary-midtone;
$onair-shadow-none: none;

// Fill colors for the logo
$onair-fill-on: $primary-midtone;
$onair-fill-off: $accent-midtone;

:root {
  --navbar-height: 72px;

  @include media-breakpoint-down(md) {
    --navbar-height: 64px;
  }
}

// Some basic navbar styling
.navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;

  input {
    padding: 8px 24px 8px 48px;
    border-radius: 40px;
    border: 1px solid $shade-3;
    width: 100%;

    font-size: 16px;
    line-height: 24px;

    font-family: var(--font-dm-sans);
    color: $shade-7;
    background-color: $shade-3;

    transition: background-color 0.2s ease;

    ::placeholder {
      color: $accent-dark;
      opacity: 0.6;
    }

    &:hover {
      cursor: pointer;
    }

    &:active,
    &:focus-visible {
      outline: none;
      background-color: $shade-1;
    }
  }
}

// Actual navbar styling for On Air
.navbar .navbar__default {
  background: linear-gradient(
    180deg,
    #19181a 0%,
    rgba(25, 24, 26, 0.0001) 100%
  );
  padding: 8px 0;
  z-index: 2;

  .container::before {
    position: absolute;
    content: '';
    inset: 0;
    background: rgba($shade-6, 0.96);
    z-index: -1;
    transition: opacity 0.4s ease;
  }

  &.navbar__default--scrolled-to-top .container::before {
    opacity: 0;
  }

  // Items on the left side of the Navbar (links)
  .navbar__links {
    flex-wrap: wrap;

    .row {
      align-items: center;
      height: 100%;
    }

    &.navbar__links--right {
      display: flex;
      flex-direction: row-reverse;
    }

    .navbar__link {
      text-align: center;
      white-space: nowrap;

      a {
        color: $shade-2;
        text-decoration: none;
        font-family: var(--font-poppins);
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;

        &:after {
          top: 6px;
          width: 0px;
          height: 2px;

          display: block;
          position: relative;
          content: '';
          background-color: $shade-1;
          margin: auto;

          transition: width 0.2s ease;
        }

        &.link--active {
          font-weight: $poppins-weight-bold;

          &:after {
            width: 32px;
            background-color: $primary-midtone;
          }
        }

        &:hover:after {
          width: 32px;
        }
      }

      &.navbar__link--icon {
        text-align: center;

        i {
          font-size: 24px;
          color: $shade-2;

          &:before {
            color: $shade-2;
            top: 2px;
          }
        }

        a.link--active:after {
          width: 16px;
        }

        a:hover:after {
          width: 16px;
        }

        i.oa-search:before {
          top: 3px; // Seems a bit misaligned, fix it
        }

        // The stripe when hovering over it
        a:after {
          top: 4px; // Normal is 6px, see below, but because icons are aligned a bit to bottom, we do 5 here
        }

        &.navbar__link--cart {
          a {
            position: relative; // Make sure the absolute child positioning works
          }

          span {
            opacity: 0;
            position: absolute;
            width: 16px;
            height: 16px;
            text-align: center;
            top: -8px;
            right: -8px;

            background-color: $secondary-midtone;
            border-radius: 50%;
            font-size: 11px;
            line-height: 16px;

            font-family: var(--font-dm-sans);
            font-weight: $dm-sans-weight-regular;

            transition: opacity 0.2s ease;

            &:hover {
              cursor: pointer;
            }
          }

          &.navbar__link--cart-filled span {
            opacity: 1;
          }
        }
      }

      &.navbar__link-hamburger {
        border-radius: 20px;
        padding: 0;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }

        i {
          font-size: 24px;

          &:before {
            color: $shade-2;
          }
        }
      }
    }
  }

  // Center of the Navbar with the logo
  .navbar__logo {
    padding-top: 8px;
    padding-bottom: 8px;

    svg {
      display: block;
      margin: auto;
      max-height: 40px;
    }

    path {
      fill: $primary-midtone;
    }

    a:focus,
    &:hover {
      cursor: pointer;

      svg {
        animation: onair-flicker-shadow 5s;

        path {
          animation: onair-flicker 5s;
        }
      }
    }
  }

  // Special items of the Navbar with the more complicated items
  .navbar__item {
    &.navbar__item--profile {
      .navbar__profile--wide {
        .navbar__item--profile-text {
          float: left;
          text-align: right;
          padding-right: 12px;

          p {
            font-weight: $dm-sans-weight-regular;
            margin: 7px 0 0;
            line-height: 6px;
            width: 100%;
          }

          h6 {
            margin: 0;
            line-height: 24px;
            width: 100%;

            white-space: nowrap;
          }
        }

        i {
          font-size: 24px;

          &.with-initials::before {
            top: 5px;
          }

          &:before {
            color: $shade-2;
          }
        }
      }

      .navbar__profile-no-initials {
        color: $text-primary;
        background-color: $primary-midtone;
        border-radius: 50%;
        width: 32px;
        height: 32px;

        font-size: 24px;
        font-family: var(--font-dm-sans);
        font-weight: $dm-sans-weight-bold;

        transition: color 0.2s ease;

        display: flex;
        justify-content: center;
        align-items: center;

        &:focus,
        &:hover {
          color: $primary-darkest;
          cursor: pointer;
        }

        i {
          &:before {
            color: $shade-2;
          }
        }
      }

      .navbar__profile--small {
        padding-top: 1px;
        color: $text-primary;
        background-color: $primary-midtone;
        border-radius: 50%;
        width: 32px;
        height: 32px;

        font-size: 13px;
        font-family: var(--font-dm-sans);
        font-weight: $dm-sans-weight-bold;

        transition: color 0.2s ease;

        &:focus,
        &:hover {
          color: $primary-darkest;
          cursor: pointer;
        }
      }
    }
  }

  .navbar__item--search {
    display: flex;
    flex-direction: row-reverse;
  }

  .navbar__search-wrapper {
    display: none; // TODO remove when search is enabled again

    width: 100%;
    max-width: 200px;
    position: relative; // Make the search addon absolute listen to his parent
    float: right; // Odd behaviour but otherwise it expands both sides

    transition: max-width 0.2s ease-in-out;

    &.navbar__search-wrapper--focussed {
      max-width: 300px;

      input {
        &:hover {
          cursor: text;
        }
      }
    }

    .navbar__search-addon {
      &:before {
        display: block;
        position: absolute;
        content: $oa-search;
        font-family: $icomoon-font-family;
        color: $accent-midtone;

        // Make sure its centered by giving it the same line-height and padding as the input
        font-size: 24px;
        line-height: 24px;
        padding: 8px;

        // Pushing it down 1px looks a slight bit better
        top: 1px;
        left: 8px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar .navbar__default {
    .navbar__links.navbar__links--right .row {
      .col {
        padding-left: 4px;
        padding-right: 4px;

        &:last-child {
          margin-right: 12px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar .navbar__default {
    .navbar__items .navbar__item {
      padding: 0 4px;
    }

    .navbar__logo svg {
      max-height: 32px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar .navbar__default .navbar__items .navbar__item.navbar__item--profile {
    .navbar__item--profile-text {
      display: none;
    }

    i:before {
      top: -1px;
    }
  }
}

// Animation keyframes, very boring...
@keyframes onair-flicker-shadow {
  // Start at nothing
  00% {
    filter: drop-shadow($onair-shadow-on);
  }

  // Keep on inbetween
  25% {
    filter: drop-shadow($onair-shadow-on);
  }
  30% {
    filter: drop-shadow($onair-shadow-on);
  }
  35% {
    filter: drop-shadow($onair-shadow-on);
  }

  // Flicker #2 long one
  37% {
    filter: drop-shadow($onair-shadow-on);
  }
  38% {
    filter: drop-shadow($onair-shadow-none);
  }
  40% {
    filter: drop-shadow($onair-shadow-on);
  }
  42% {
    filter: drop-shadow($onair-shadow-none);
  }
  44% {
    filter: drop-shadow($onair-shadow-on);
  }

  // Flicker 3 short one
  70% {
    filter: drop-shadow($onair-shadow-on);
  }
  72% {
    filter: drop-shadow($onair-shadow-none);
  }
  74% {
    filter: drop-shadow($onair-shadow-on);
  }

  // End
  95% {
    filter: drop-shadow($onair-shadow-on);
  }
  100% {
    filter: drop-shadow($onair-shadow-none);
  }
}

@keyframes onair-flicker {
  // Start at nothing
  00% {
    fill: $onair-fill-on;
  }

  // Keep on inbetween
  25% {
    fill: $onair-fill-on;
  }
  30% {
    fill: $onair-fill-on;
  }
  35% {
    fill: $onair-fill-on;
  }

  // Flicker #2
  37% {
    fill: $onair-fill-on;
  }
  38% {
    fill: $onair-fill-off;
  }
  40% {
    fill: $onair-fill-on;
  }
  42% {
    fill: $onair-fill-off;
  }
  44% {
    fill: $onair-fill-on;
  }

  // Flicker 3 short one
  70% {
    fill: $onair-fill-on;
  }
  72% {
    fill: $onair-fill-off;
  }
  74% {
    fill: $onair-fill-on;
  }

  // End
  100% {
    fill: $onair-fill-on;
  }
}
