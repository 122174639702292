.login-register__wrapper {
  display: flex;
  position: relative; // Make sure the overlay listens to this component

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  z-index: 45;
  margin-top: -64px; // Delete space between navbar and page

  .login-register__introduction {
    display: none;
    position: absolute;
    z-index: 50;
    left: 0;
    right: 0;
    top: 0;
    height: 85%;
    width: 100%;
  }

  .btn-onair {
    margin: 8px 0 0 0;
  }

  .login-register__overlay-wrapper {
    position: relative;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    max-width: 100rem;
    pointer-events: none;

    z-index: 50;
  }

  .login-register__overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    background-color: $bg-dark;
    padding-top: 40px;
    transition: left 0.4s ease;

    &.login-register__overlay--left {
      left: 0;
    }
  }

  .login-register__form-wrap {
    position: relative;
    margin-top: calc($header-height-mobile + 32px);
  }

  .login-register__screen {
    opacity: 0;
    width: 100%;
    padding: 0;
    height: 0;
    transition: opacity 0.4s ease;

    .onair-input__wrapper {
      padding-right: 0;
    }

    &.login-register__screen--visible {
      height: auto;
      opacity: 1;
      pointer-events: auto;
      padding: 0 32px 32px;
    }
  }

  .login-register__text-separator {
    color: $shade-2;
    margin-block: 32px;
    display: flex;
    flex-direction: row;

    &:before,
    &:after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }

  .login-register__social-button {
    width: 48px;
    height: 48px;
    padding: 8px;
    border-radius: 3px;
    border-width: 1px;
    background-color: $shade-2;
    border-color: $shade-2;

    svg {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  .login-register__social-button--apple {
    svg {
      margin-left: 3px;

      * {
        fill: $shade-6;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .login-register__wrapper {
    .login-register__form-wrap {
      margin-top: calc($header-height + 32px);
    }
  }
}

@include media-breakpoint-up(lg) {
  .login-register__wrapper {
    min-height: 1000px;
    overflow: hidden;
    background-size: 100% 100% !important; // Make sure the background does not zoom in/zoom out during error message

    .login-register__introduction {
      display: block;
    }

    .login-register__overlay {
      width: 50%;

      &.login-register__overlay--left {
        left: 50%;
      }
    }

    .login-register__screen {
      position: absolute;
      top: 0;
      height: auto;
    }
    .position-relative-lg {
      position: relative; // As it was absolute in desktop view, the form was not able to take the height of the error message
    }
  }
}
